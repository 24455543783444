import React from 'react';
import { animated, useSpring } from 'react-spring';
import useStore from '../../store/store';
import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import * as style from './Header.module.css';

const Header = () => {
  return (
    <header className={style.wrapper}>
      <Logo />
      <Menu />
    </header>
  );
};

export default Header;
