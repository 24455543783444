import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import useThumbnailImage from '../../hooks/useThumbnailImage';
import useStore from '../../store/store';
import { CarouselItemPropsType } from '../../types/carousel';
import * as style from './CarouselItem.module.css';

const CarouselItem = ({ data }: CarouselItemPropsType) => {
  const thumbnailImage = useThumbnailImage(data.img);
  const setCursorHover = useStore((state) => state.setCursorHover);

  return (
    <div
      onMouseOver={() => setCursorHover('thumbnail')}
      onMouseOut={() => setCursorHover(null)}
      className={style.wrapper}
    >
      <a href={data.link} target="_blank" rel="noopener noreferrer">
        {thumbnailImage && (
          <GatsbyImage
            imgClassName={style.image}
            image={thumbnailImage}
            alt={data.name}
          />
        )}
      </a>
    </div>
  );
};

export default CarouselItem;
