import React from 'react';
import { MobileMenuButtonPropsType } from '../../types/mobileMenu';
import * as style from './MobileMenuButton.module.css';

const MenuBurgerButton = ({
  isOpen,
  toggleMenu,
}: MobileMenuButtonPropsType) => {
  return (
    <div
      role="button"
      aria-pressed={isOpen}
      tabIndex={0}
      onClick={toggleMenu}
      onKeyDown={toggleMenu}
      className={isOpen ? `${style.button} ${style.active}` : `${style.button}`}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default MenuBurgerButton;
