import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated, useTrail, useSprings } from 'react-spring';
import ArrowIcon from '../../components/ArrowIcon/ArrowIcon';
import BluredText from '../../components/BluredText/BluredText';
import useSection from '../../hooks/useSection';
import * as style from './AboutSection.module.css';

const TECH_STACK = [
  'React',
  'Redux',
  'NextJS',
  'Gatsby',
  'GraphQL',
  'TypeScript',
];

const AboutSection = () => {
  const { ref } = useSection(2, 0.7);
  const { inView, ref: refText } = useInView({
    triggerOnce: true,
  });
  const styles = useSpring({
    opacity: inView ? 1 : 0,
    reverse: false,
  });

  const trail = useSprings(
    TECH_STACK.length,
    TECH_STACK.map((_, index) => ({
      delay: inView ? 100 + index * 100 : 0,
      opacity: inView ? 1 : 0,
      x: inView ? 0 : 20,
      height: inView ? 22 : 0,
      from: { opacity: 0, x: 20, height: 0 },
    }))
  );

  return (
    <div id="about" ref={ref} className={style.wrapper}>
      <div className={style.content}>
        <h2 ref={refText}>
          <BluredText>About me</BluredText>
        </h2>
        <animated.p style={styles}>
          Hi! I’m Marcin, a frontend developer based in Poland currently
          looking for a full-time remote job. Most of the projects in my
          portfolio are personal, but I also have commercial experience in
          building landing pages. Tech stack I have been working with:
        </animated.p>
        <ul>
          {trail.map(({ height, ...props }, index) => (
            <animated.li key={index} style={props}>
              <animated.div className={style.techStack} style={{ height }}>
                <ArrowIcon /> <span>{TECH_STACK[index]}</span>
              </animated.div>
            </animated.li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AboutSection;
