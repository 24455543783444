import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const Seo = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          image
          twitterUsername
          siteUrl
          author
          description
          titleTemplate
        }
      }
    }
  `);

  return (
    <Helmet
      title={siteMetadata.title}
      titleTemplate={siteMetadata.titleTemplate}
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <meta name="description" content={siteMetadata.description} />
      <meta name="image" content={siteMetadata.image} />
      {siteMetadata.url && (
        <meta property="og:url" content={siteMetadata.url} />
      )}
      {siteMetadata.title && (
        <meta property="og:title" content={siteMetadata.title} />
      )}
      {siteMetadata.description && (
        <meta property="og:description" content={siteMetadata.description} />
      )}
      {siteMetadata.image && (
        <meta property="og:image" content={siteMetadata.image} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {siteMetadata.twitterUsername && (
        <meta name="twitter:creator" content={siteMetadata.twitterUsername} />
      )}
      {siteMetadata.title && (
        <meta name="twitter:title" content={siteMetadata.title} />
      )}
      {siteMetadata.description && (
        <meta name="twitter:description" content={siteMetadata.description} />
      )}
      {siteMetadata.image && (
        <meta name="twitter:image" content={siteMetadata.image} />
      )}
      {siteMetadata.themeColor && (
        <meta name="theme-color" content={siteMetadata.themeColor} />
      )}
    </Helmet>
  );
};

export default Seo;
