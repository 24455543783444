import * as React from 'react';
import * as style from './HeroBackgroundMobile.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const HeroBackgroundMobile = () => {
  return (
    <div className={style.wrapper}>
      <StaticImage src="../../images/icon.png" alt="Background" />
    </div>
  );
};

export default HeroBackgroundMobile;
