import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import * as style from './Cursor.module.css';
import { easeQuadOut } from 'd3-ease';
import useStore from '../../store/store';
import ArrowUpIcon from '../ArrowUpIcon/ArrowUpIcon';

const Cursor = () => {
  const cursorHover = useStore((state) => state.cursorHover);
  const styles = useSpring({
    transform: cursorHover === 'thumbnail' ? 'scale(8)' : 'scale(1)',
  });

  const arrow = useSpring({
    opacity: cursorHover === 'thumbnail' ? '1' : '0',
    config: {
      duration: 150,
      easing: easeQuadOut,
    },
  });

  const [props, set] = useSpring(() => ({
    top: 0,
    left: 0,
    opacity: 0,
    config: {
      duration: 150,
      easing: easeQuadOut,
    },
  }));

  useEffect(() => {
    set.start({
      left: window.innerWidth / 2,
      top: window.innerHeight / 2,
    });

    const handleMouseMove = (event: MouseEvent) => {
      set.start({
        opacity: 1,
        left: event.clientX - 5,
        top: event.clientY - 5,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <animated.div style={props} className={style.wrapper}>
      <animated.div style={styles} className={style.circle}></animated.div>
      <animated.span style={arrow} className={style.arrow}>
        <ArrowUpIcon />
      </animated.span>
    </animated.div>
  );
};

export default Cursor;
