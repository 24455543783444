import React from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';
import { SocialsPropsType } from '../../types/socials';
import BehanceIcon from '../BehanceIcon/BehanceIcon';
import GitHubIcon from '../GitHubIcon/GitHubIcon';
import LinkedInIcon from '../LinkedInIcon/LinkedInIcon';
import * as style from './Socials.module.css';

const CONTENT = [
  {
    name: 'GitHub',
    link: 'https://github.com/schriker',
    icon: <GitHubIcon />,
  },
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/in/marcin-janus-b5718b147',
    icon: <LinkedInIcon />,
  },
  {
    name: 'BeHance',
    link: 'https://www.behance.net/gallery/56986791/Code-Morsel',
    icon: <BehanceIcon />,
  },
];

const Socials = ({ isMobile = false }: SocialsPropsType) => {
  const { inView, ref } = useInView({
    triggerOnce: true,
  });

  const styles = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : isMobile ? 30 : 100,
    reverse: false,
    delay: isMobile ? 200 : 500,
  });
  return (
    <animated.div
      ref={ref}
      style={styles}
      className={`${style.wrapper} ${isMobile ? style.mobile : ''}`}
    >
      <ul>
        {CONTENT.map((item, index) => (
          <li key={index}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.name}
              {item.icon}
            </a>
          </li>
        ))}
      </ul>
      <div className={style.line}></div>
    </animated.div>
  );
};

export default Socials;
