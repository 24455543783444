import React from 'react';
import { Link } from 'gatsby';
import * as style from './Menu.module.css';
import { animated, useSprings } from 'react-spring';
import useStore from '../../store/store';
import MobileMenu from '../MobileMenu/MobileMenu';

const items = [
  {
    name: 'Home',
    link: '',
  },
  {
    name: 'About',
    link: '#about',
  },
  {
    name: 'Portfolio',
    link: '#portfolio',
  },
  {
    name: 'Landings',
    link: '#landings',
  },
  {
    name: 'Contact',
    link: 'mailto:mail@janusmarcin.pl',
  },
];

const Menu = () => {
  const section = useStore((state) => state.section);
  const animatedLinks = useSprings(
    items.length,
    items.map((_, index) => ({
      opacity: 1,
      delay: 600 + index * 100,
      transform: `translateY(0px)`,
      from: { opacity: 0, transform: `translateY(-50px)` },
    }))
  );

  return (
    <nav>
      <ul className={style.wrapper}>
        {animatedLinks.map((props, index) => (
          <animated.li style={props} key={index}>
            {items[index].name === 'Contact' ? (
              <a href={items[index].link}>{items[index].name}</a>
            ) : (
              <Link
                className={section === index + 1 ? style.active : ''}
                to={`/${items[index].link}`}
              >
                {items[index].name}
              </Link>
            )}
          </animated.li>
        ))}
      </ul>
      <MobileMenu links={items} />
    </nav>
  );
};

export default Menu;
