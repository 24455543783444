import React from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useSprings } from 'react-spring';
import { BluredTextPropsType } from '../../types/bluredText';

const BluredText = ({ children }: BluredTextPropsType) => {
  const { inView, ref } = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const letters = children.split('');
  const springs = useSprings(
    letters.length,
    letters.map(() => {
      const randomValue = Math.floor(Math.random() * 500);
      return {
        filter: inView ? 'blur(0px)' : 'blur(6px)',
        opacity: inView ? 1 : 0,
        delay: randomValue,
        reverse: false,
        config: {
          duration: 400,
        },
      };
    })
  );

  return (
    <span ref={ref}>
      {springs.map((styles, index) => (
        <animated.span key={index} style={styles}>
          {letters[index]}
        </animated.span>
      ))}
    </span>
  );
};

export default BluredText;
