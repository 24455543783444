import React from 'react';
import BluredText from '../../components/BluredText/BluredText';
import Project from '../../components/Project/Project';
import useSection from '../../hooks/useSection';
import * as style from './PortfolioSection.module.css';

const CONTENT = [
  {
    name: 'Jarchiwum v2',
    description:
      'The app allows users to subscribe their favorite twitch streamers, and watch streams that they have missed. User then can add their own channels and/or remove them. Mark videos as watched and bookmarked.',
    link: 'https://jarchiwum.pl/',
    github: 'https://github.com/schriker/jar-next',
    img: 'jarchiwumv2',
    stack: ['NextJS', 'React', 'Redux'],
  },
  {
    name: 'Derum',
    description:
      'Javascript Fullstack Reddit/Discord like social platform. Users can create rooms with separate chats, add news, create articles, chat with others.',
    link: 'https://www.derum.pl/',
    github: 'https://github.com/schriker/derum',
    img: 'derum',
    stack: ['NestJS', 'Graphql', 'Postgresql', 'NextJS', 'React', 'Apollo'],
  },
  {
    name: 'Usabilly',
    description:
      'Static site built with GatsbyJS for digital product agency specializing in enhancement of enterprise efficiency by creating custom-tailored user experience.',
    link: 'https://usabilly.io/',
    img: 'usabilly',
    stack: ['React', 'GatsbyJS'],
  },
  {
    name: 'Online Bakery',
    description:
      'Graphql API built for online bakery where users can create account, sell their products, chat with customers etc. You can play with it in graphql playground.',
    link: 'https://gql.jarchiwum.pl/graphql',
    github: 'https://github.com/schriker/bakery-api/',
    img: 'gql',
    stack: ['NestJS', 'GrqphQL', 'PostgreSQL'],
  },
  {
    name: 'Fotografia Przyrodnicza',
    description:
      'Gatsby with Strapi on backend to fetch images and data from GraphQL endpoint. Providing EXIF data directly from image file.',
    link: 'https://foto.janusmarcin.pl/',
    github: 'https://github.com/schriker/gatsby-foto',
    img: 'foto',
    stack: ['React', 'Redux', 'GatsbyJS'],
  },
  {
    name: 'ITBoard',
    description:
      'Main goal was to build fullstack app in JS from scratch, to practice user authentication and server side rendering.',
    link: 'https://it.jarchiwum.pl/',
    github: 'https://github.com/schriker/itboard-client',
    img: 'itboard',
    stack: ['React', 'Redux', 'NextJS', 'NodeJS', 'Express', 'MongoDB'],
  },
  {
    name: 'PoorchatBot',
    description:
      'IRC Bot which listen users messages and saved them in the mongo database, so later on, after live stream is over, users can watch the live chat replay. It can also send messages.',
    github: 'https://github.com/schriker/poorchatbot',
    img: 'poorchatbot',
    stack: ['NodeJS', 'Express', 'MongoDB'],
  },
  {
    name: 'Play2Gether',
    description:
      'Single page app build with React for social platform that will allow to find people for cooperative or multiplayer games. App is not finished yet, there are a few things that work and a lot that still need to be done. Check github for details.',
    link: 'https://p2g.janusmarcin.pl/',
    github: 'https://github.com/schriker/Play2Gether',
    img: 'play2gether',
    stack: ['React', 'Redux', 'Firebase'],
  },
  {
    name: 'Jarchiwum',
    description:
      'The app allows users to subscribe their favorite twitch streamers, and watch streams that they have missed. User then can add their own channels and/or remove them. Mark videos as watched and bookmarked.',
    github: 'https://github.com/schriker/jar-vue',
    img: 'jarchiwum',
    stack: ['Vue', 'Vuex', 'Firebase'],
  },
  {
    name: 'The Spacjo Strzelec',
    description:
      'Whole app was build with help of React Hooks API wich was introduced with 16.8.0. Main goal was to build global state based on Context API and useReducer hook. So I can omit Redux and still have similar functionality of flux like state control.',
    link: 'https://schriker.github.io/spacjo/',
    github: 'https://github.com/schriker/spacjo',
    img: 'spacjo',
    stack: ['React'],
  },
];

const PortfolioSection = () => {
  const { ref } = useSection(3, 0.1);

  return (
    <div id="portfolio" ref={ref} className={style.wrapper}>
      <div className={style.content}>
        <h2>
          <BluredText>Portfolio</BluredText>
        </h2>
        {CONTENT.map((project, index) => (
          <Project index={index} key={index} data={project} />
        ))}
      </div>
    </div>
  );
};

export default PortfolioSection;
