import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

const useThumbnailImage = (name: string) => {
  const imagesData = useStaticQuery(graphql`
    query MyQuery {
      allFile {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const imageData = imagesData.allFile.edges.filter((image: any) => {
    return image.node.name.toLocaleLowerCase() === name.toLocaleLowerCase();
  })[0]?.node;

  return getImage(imageData);
};

export default useThumbnailImage;
