import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import useStore from '../store/store';

const useSection = (sectionNumber: number, threshold: number) => {
  const { ref, inView } = useInView({
    threshold: threshold,
  });
  const setSection = useStore((state) => state.setSection);

  useEffect(() => {
    if (inView) {
      setSection(sectionNumber);
    }
  }, [inView]);

  return { ref };
};

export default useSection;
