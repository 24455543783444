import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import BluredText from '../../components/BluredText/BluredText';
import CarouselItem from '../../components/CarouselItem/CarouselItem';
import useSection from '../../hooks/useSection';
import * as style from './LandingsSection.module.css';
import ArrowIcon from '../../components/ArrowIcon/ArrowIcon';
import { animated, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const CONTENT = [
  {
    name: 'Kancelaria Radcy Prawnego',
    link: 'https://radcaprawnystalski.pl/',
    img: 'kancelaria',
  },
  {
    name: 'Videosharks',
    link: 'https://github.com/schriker/video-sharks',
    img: 'videosharks',
  },
  {
    name: 'Fourstart',
    link: 'https://github.com/schriker/fourstar',
    img: 'fourstar',
  },
  {
    name: 'Homepage',
    link: 'https://janusmarcin.pl/homepage/',
    img: 'homepage',
  },
  {
    name: 'Wedding Store',
    link: 'https://github.com/schriker/weding-store',
    img: 'weddingstore',
  },
  {
    name: 'JIVR',
    link: 'https://github.com/schriker/jivr',
    img: 'jivr',
  },
  {
    name: 'Vivid',
    link: 'https://github.com/schriker/vivid',
    img: 'vivid',
  },
];

const LandingsSection = () => {
  const { ref } = useSection(4, 0.8);
  const nextRef = useRef<HTMLDivElement | null>(null);
  const prevRef = useRef<HTMLDivElement | null>(null);

  const { inView, ref: sectionRef } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const styles = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 30,
    reverse: false,
  });

  return (
    <div id="landings" ref={ref} className={style.wrapper}>
      <div className={style.content}>
        <h2 ref={sectionRef}>
          <BluredText>Landings</BluredText>
        </h2>
        <animated.div style={styles} className={style.carousel}>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: nextRef.current,
              prevEl: prevRef.current,
            }}
            spaceBetween={20}
            slidesPerView={1}
            autoplay
            loop
            breakpoints={{
              '1024': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              '768': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
            }}
          >
            {CONTENT.map((item, index) => (
              <SwiperSlide key={index}>
                <CarouselItem data={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div ref={nextRef} className={style.next}>
            <ArrowIcon />
          </div>
          <div ref={prevRef} className={style.prev}>
            <ArrowIcon />
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default LandingsSection;
