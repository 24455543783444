import React, { Suspense } from 'react';
import Cursor from '../components/Cursor/Cursor';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import Socials from '../components/Socials/Socials';
import AboutSection from '../sections/AboutSection/AboutSection';
import HeroSection from '../sections/HeroSection/HeroSection';
import LandingsSection from '../sections/LandingsSection/LandingsSection';
import PortfolioSection from '../sections/PortfolioSection/PortfolioSection';
import useWindowSize from '../hooks/useWindowSize';
import HeroBackgroundMobile from '../components/HeroBackgroundMobile/HeroBackgroundMobile';
const HeroBackground = React.lazy(
  () => import('../components/HeroBackground/HeroBackground')
);

const IndexPage = () => {
  const { width } = useWindowSize();
  return (
    <>
      {width > 715 && <Cursor />}
      {width < 715 ? (
        <HeroBackgroundMobile />
      ) : (
        <Suspense fallback={null}>
          <HeroBackground />
        </Suspense>
      )}
      <Socials />
      <Seo />
      <Layout>
        <HeroSection />
        <AboutSection />
        <PortfolioSection />
        <LandingsSection />
      </Layout>
    </>
  );
};

export default IndexPage;
