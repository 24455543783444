import React from 'react';
import Header from '../../components/Header/Header';
import HeroText from '../../components/HeroText/HeroText';
import useSection from '../../hooks/useSection';

const HeroSection = () => {
  const { ref } = useSection(1, 0.9);

  return (
    <div ref={ref}>
      <Header />
      <HeroText />
    </div>
  );
};

export default HeroSection;
