import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';
import { Link } from 'gatsby';
import * as style from './MobileMenu.module.css';
import { MobileMenuPropsType } from '../../types/mobileMenu';
import MobileMenuButton from '../MobileMenuButton/MobileMenuButton';
import Socials from '../Socials/Socials';
import useStore from '../../store/store';

const MobileMenu = ({ links }: MobileMenuPropsType) => {
  const [isOpen, toggleMenu] = useState(false);
  const section = useStore((state) => state.section);

  const fadeIn = useTransition(isOpen, {
    enter: {
      opacity: 1,
      transform: 'translateX(0vw)',
    },
    leave: {
      opacity: 0,
      transform: 'translateX(100vw)',
    },
    from: {
      opacity: 0,
      transform: 'translateX(100vw)',
    },
  });

  return (
    <div>
      <MobileMenuButton
        isOpen={isOpen}
        toggleMenu={() => toggleMenu(!isOpen)}
      />
      {fadeIn(
        ({ opacity, transform }, item) =>
          item && (
            <animated.div
              style={{ opacity: opacity }}
              className={style.wrapper}
            >
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => toggleMenu(false)}
                onClick={() => toggleMenu(false)}
                className={style.backdrop}
              ></div>
              <animated.div
                style={{ transform: transform }}
                className={style.container}
              >
                <ul className={style.menu}>
                  {links.map((link, index) => (
                    <li key={index}>
                      {link.name === 'Contact' ? (
                        <a href={link.link}>{link.name}</a>
                      ) : (
                        <Link
                          className={section === index + 1 ? style.active : ''}
                          to={`/${link.link}`}
                        >
                          {link.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
                <Socials isMobile />
              </animated.div>
            </animated.div>
          )
      )}
    </div>
  );
};

export default MobileMenu;
