import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import BluredText from '../BluredText/BluredText';
import Button from '../Button/Button';
import * as style from './HeroText.module.css';

const HeroText = () => {
  const { inView, ref } = useInView({
    triggerOnce: true,
  });

  const styles = useSpring({
    opacity: inView ? 1 : 0,
    delay: inView ? 500 : 0,
    reverse: false,
  });

  return (
    <div className={style.wrapper}>
      <div>
        <h1 ref={ref}>
          <BluredText>Hi, my name is Marcin.</BluredText>
        </h1>
        <div>
          <animated.p style={styles}>
            Frontend developer with React and some cool personal projects.
          </animated.p>
        </div>
        <animated.div style={styles} className={style.buttonWrapper}>
          <Button text="Contact Me" href="mailto:mail@janusmarcin.pl" />
        </animated.div>
      </div>
    </div>
  );
};

export default HeroText;
