import React from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo.svg';
import * as style from './Logo.module.css';

const Logo = () => {
  return (
    <Link to="/">
      <div data-aos="fade-in" data-aos-delay="500" className={style.wrapper}>
        <img src={logo} alt="Marcin Janus" />
        <span>Marcin Janus</span>
      </div>
    </Link>
  );
};

export default Logo;
