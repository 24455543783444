import React from 'react';
import { ButtonPropsType } from '../../types/button';
import * as style from './Button.module.css';

const Button = ({
  text,
  href,
  small = false,
  ...rest
}: ButtonPropsType &
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >) => {
  return (
    <a
      className={`${style.button} ${small ? style.small : ''}`}
      href={href}
      {...rest}
    >
      {text}
    </a>
  );
};

export default Button;
