import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated, useSpringRef, useChain } from 'react-spring';
import { ProjectPropsType } from '../../types/project';
import Button from '../Button/Button';
import * as style from './Project.module.css';
import { easeCircleIn } from 'd3-ease';
import useThumbnailImage from '../../hooks/useThumbnailImage';
import useStore from '../../store/store';

const Project = ({ data, index }: ProjectPropsType) => {
  const setCursorHover = useStore((state) => state.setCursorHover);
  const { inView, ref } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const { opacity, y } = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 30,
    reverse: false,
  });

  const transformRef = useSpringRef();
  const { transform } = useSpring({
    transform: inView ? 'translate(100%, 0)' : 'translate(-50%, 0)',
    config: {
      duration: 300,
      easing: easeCircleIn,
    },
    ref: transformRef,
  });

  const opacityRef = useSpringRef();
  const { opacity: titleOpacity } = useSpring({
    opacity: inView ? 1 : 0,
    ref: opacityRef,
  });

  useChain([transformRef, opacityRef], [0, 0.2]);

  const thumbnailImage = useThumbnailImage(data.img);

  return (
    <animated.div
      style={{ opacity, y }}
      ref={ref}
      className={`${style.wrapper} ${index % 2 === 0 ? style.reverse : ''}`}
    >
      <div>
        <div className={style.title}>
          <h3>
            <animated.div
              style={{ transform }}
              className={style.whiteBar}
            ></animated.div>
            <animated.span style={{ opacity: titleOpacity }}>
              {data.name}
            </animated.span>
          </h3>
          <div className={style.links}>
            {data.link && (
              <Button
                rel="noopener noreferrer"
                target="_blank"
                small
                href={data.link}
                text="Live"
              />
            )}
            {data.github && (
              <Button
                rel="noopener noreferrer"
                target="_blank"
                small
                href={data.github}
                text="Code"
              />
            )}
          </div>
        </div>
        <div className={style.content}>
          <p>{data.description}</p>
        </div>
        <ul>
          {data.stack.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <div
        className={style.thumb}
        onMouseOver={() => setCursorHover('thumbnail')}
        onMouseOut={() => setCursorHover(null)}
      >
        <a
          href={data.link || data.github}
          rel="noopener noreferrer"
          target="_blank"
        >
          {thumbnailImage && (
            <GatsbyImage imgClassName={style.image} image={thumbnailImage} alt={data.name} />
          )}
        </a>
      </div>
    </animated.div>
  );
};

export default Project;
