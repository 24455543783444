import create from 'zustand';

interface storeState {
  section: number;
  setSection: (section: number) => void;
  scrollDirecton: 'up' | 'down' | 'top';
  setScrollDirection: (direction: 'up' | 'down' | 'top') => void;
  cursorHover: 'thumbnail' | 'link' | null;
  setCursorHover: (type: 'thumbnail' | 'link' | null) => void;
}

const useStore = create<storeState>((set) => ({
  section: 1,
  scrollDirecton: 'top',
  cursorHover: null,
  setSection: (section) => set({ section: section }),
  setScrollDirection: (direction) => set({ scrollDirecton: direction }),
  setCursorHover: (type) => set({ cursorHover: type }),
}));

export default useStore;
